import { localStorage } from 'dibs-browser-storage';

import {
    MAX_STORAGE_DAYS,
    MAX_RECENTLY_VIEWED_ITEMS_STORAGE,
    RECENT_HISTORY_LIST,
} from './recentHistoryConstants';

// types
import { type LocalHistoryItemType } from './recentHistoryTypes';

const daysBetween = (timestamp1: number, timestamp2: number): number => {
    const timeDiff = Math.abs(timestamp1 - timestamp2);
    const oneDayInMs = 86400000; // 24h/d * 60m/h * 60s/m * 1000ms/s
    return Math.round(timeDiff / oneDayInMs);
};

export const isValidRecentHistoryItem = (
    item: LocalHistoryItemType,
    currentItemId?: string | null
): boolean => {
    const currentTime = new Date().getTime();
    const isCurrentItem = item.itemId === (currentItemId || '');
    return (
        daysBetween(parseInt(item.timestamp, 10), currentTime) < MAX_STORAGE_DAYS && !isCurrentItem
    );
};

export const getLocalRecentHistoryList = (
    currentItemId?: string | null,
    maxRecentHistoryItems: number = MAX_RECENTLY_VIEWED_ITEMS_STORAGE
): LocalHistoryItemType[] => {
    const localRecentHistoryList =
        (localStorage.getItem(RECENT_HISTORY_LIST) as LocalHistoryItemType[]) || [];
    const recentHistoryList: LocalHistoryItemType[] = [];
    const index = maxRecentHistoryItems * -1;

    if (Array.isArray(localRecentHistoryList)) {
        localRecentHistoryList.forEach((localRecentHistoryItem: LocalHistoryItemType) => {
            if (isValidRecentHistoryItem(localRecentHistoryItem, currentItemId)) {
                recentHistoryList.push(localRecentHistoryItem);
            }
        });
    }

    if (recentHistoryList.length > maxRecentHistoryItems) {
        return recentHistoryList.slice(index);
    }
    return recentHistoryList;
};
