import { type FC } from 'react';
import { Badge } from 'dibs-elements/exports/Badge';
import { MoreOptionsMultiSku } from 'dibs-more-options-multisku/exports/MoreOptionsMultiSku';
import styles from './SkusBadge.scss';

export const SkusBadge: FC = () => (
    <Badge type="neutral">
        <MoreOptionsMultiSku classes={styles.badgeIcon} />
        <span className={styles.badgeText}>SKUS</span>
    </Badge>
);
