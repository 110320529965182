import { type FC } from 'react';
import { useFragment, graphql } from 'react-relay';

import { Badge } from 'dibs-elements/exports/Badge';

import { type InaTransactionsBadge_seller$key } from './__generated__/InaTransactionsBadge_seller.graphql';

import dibsCss from 'dibs-css';

const InaTransactionsBadge: FC<{
    seller: InaTransactionsBadge_seller$key | null | undefined;
    wrapperClass?: string;
}> = ({ seller: sellerRef, wrapperClass }) => {
    const seller = useFragment(
        graphql`
            fragment InaTransactionsBadge_seller on Seller {
                inaTransactions {
                    inaCount
                    inaPercentage
                    isPercentage
                }
            }
        `,
        sellerRef
    );

    const { isPercentage, inaPercentage, inaCount } = seller?.inaTransactions || {};

    const showInaRateBadge = isPercentage && inaPercentage && inaPercentage > 2;
    const showInaCountBadge = !isPercentage && inaCount && inaCount > 2;

    if (!showInaRateBadge && !showInaCountBadge) {
        return null;
    }

    return (
        <div className={wrapperClass}>
            <Badge type="alert" size="small-heavy">
                <span className={dibsCss.sassyFontBodySizeXSmallTypeHeavy}>
                    {showInaRateBadge && `INA Rate | ${inaPercentage}%`}
                    {showInaCountBadge && `INA Count | ${inaCount}`}
                </span>
            </Badge>
        </div>
    );
};

export default InaTransactionsBadge;
