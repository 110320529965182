import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';

type Props = {
    creatorName: string;
};

const CreatedBy: FC<Props> = ({ creatorName }) => (
    <span data-tn="portfolio-row-creator">
        <FormattedMessage
            id="dc.searchProductTile.createdBy"
            defaultMessage={`By {creatorName}`}
            values={{ creatorName }}
        />
    </span>
);

export default CreatedBy;
