import { ClientSuspense } from 'dibs-elements/exports/ClientSuspense';
import { type FC, lazy } from 'react';
import { graphql, useFragment } from 'react-relay';
import { type Props as ItemShippingPrequotesProps } from './ItemShippingPrequotes';

import { type ItemShippingPrequotesLazy_item$key as Item } from './__generated__/ItemShippingPrequotesLazy_item.graphql';

const ItemShippingPrequotes = lazy(
    () => import(/* webpackChunkName: "ItemShippingPrequotes" */ './ItemShippingPrequotes')
);

const itemFragment = graphql`
    fragment ItemShippingPrequotesLazy_item on Item {
        ...ItemShippingPrequotes_item
    }
`;

type Props = {
    item: Item;
} & Omit<ItemShippingPrequotesProps, 'item'>;

const ItemShippingPrequotesLazy: FC<Props> = ({ item: itemRef, ...props }) => {
    const item = useFragment(itemFragment, itemRef);

    return (
        <ClientSuspense fallback={null}>
            <ItemShippingPrequotes item={item} {...props} />
        </ClientSuspense>
    );
};

export default ItemShippingPrequotesLazy;
