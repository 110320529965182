import dibsCss from 'dibs-css';
import classnames from 'classnames';
import { type FunctionComponent } from 'react';

type MoreOptionsProps = {
    className?: string;
};

const MoreOptions: FunctionComponent<MoreOptionsProps> = props => {
    return (
        <svg
            className={classnames(dibsCss.dibsIcon, props.className)}
            data-tn={'more-options'}
            viewBox="0 0 250 250"
        >
            <circle cx="125" cy="62.5" r="41.5" className="circle-0" />
            <circle cx="41.5" cy="187.5" r="41.5" className="circle-1" />
            <circle cx="208.5" cy="187.5" r="41.5" className="circle-2" />
        </svg>
    );
};
export default MoreOptions;
