import { type FC } from 'react';
import css from './styles/more-options-multisku.scss';
import MoreOptions from 'dibs-icons/exports/legacy/MoreOptions';

type MoreOptionsMultiSkuProps = {
    classes?: string;
};

const MoreOptionsMultiSku: FC<MoreOptionsMultiSkuProps> = ({ classes }) => {
    const additionalClasses = classes ? classes : '';
    return <MoreOptions className={`${css.moreOptionsMultisku} ${additionalClasses}`} />;
};

export { MoreOptionsMultiSku };
